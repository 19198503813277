
import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";

type AvailableSlot = {
  start: string;
  end: string;
};

@Component({
  filters: {
    format(value: string, format: string, appLanguage: string) {
      if (!value) return;

      return (
        moment(value)
          // .locale(appLanguage)
          .format(format)
      );
    }
  }
})
export default class ResourceLocationComponent extends Vue {
  @Prop(String) readonly city!: string;
  @Prop(String) readonly street!: string;

  expanded = null;
}
