
import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";

type Upload = {
  id: number;
  url: string;
};

@Component({
  filters: {
    format(value: string, format: string, appLanguage: string) {
      if (!value) return;

      return (
        moment(value)
          // .locale(appLanguage)
          .format(format)
      );
    }
  }
})
export default class ResourceGalleryComponent extends Vue {
  @Prop(Array) readonly uploads!: Upload[];
}
