
import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";

import ResourceGallery from "../components/ResourceGallery.vue";
import ResourceLocation from "../components/ResourceLocation.vue";
import ResourceDescription from "../components/ResourceDescription.vue";

import Schedule from "./Schedule.vue";

import type { AvailableSlotData, AvailableSlot } from "../store";
import type { Tile } from "../../tile/store";

type Interval = {
  name: string;
  value: number;
};

enum IntervalType {
  "Day" = 1,
  "Hour" = 2,
  "Minute" = 3
}

@Component({
  components: {
    Schedule,
    ResourceGallery,
    ResourceLocation,
    ResourceDescription,
    AvailableSlotsCalendar: () => import("../components/AvailableSlotsCalendar.vue"),
    Alert: () => import("@/app/modules/_global/components/Alert.vue"),
    datepicker_: () => import("@/app/modules/service/builder/elements/DatePicker.vue"),
    input_: () => import("@/app/modules/service/builder/elements/Input.vue"),
    select_: () => import("@/app/modules/service/builder/elements/Select.vue")
  },
  filters: {
    format(value: string, format: string, appLanguage: string) {
      if (!value) return;

      return moment(value).locale(appLanguage).format(format);
    }
  }
})
export default class ResourcePage extends Vue {
  @Prop(Object) readonly tile!: Tile;

  intersecting = false;
  dateMin = moment().format("YYYY-MM-DD");

  slotDialog = false;
  availableSlotsDialog = false;

  formValid = false;
  formErrors = null;
  dialogErrors = null;

  formData = {
    date_from: moment().format("YYYY-MM-DD"),
    date_to: "",
    duration: "",
    number_of_visitors: 1
  };

  availableSlots: AvailableSlotData[] = [];
  selectedSlot: null | AvailableSlot = null;

  get userData() {
    return this.$store.getters["profile/data"];
  }

  get appLanguage() {
    return this.$store.getters["app/language"];
  }

  get typeId() {
    return this.tile.contentable.type.id;
  }

  get isAvailableBookingApp() {
    return this.tile.contentable.is_available_booking_app;
  }

  get unavailable() {
    const massMarket = this.tile.contentable.mass_market;
    return (!massMarket && !this.hasRole("inhabitant")) || this.hasRole("guest");
  }

  get uploadsLength() {
    return this.tile.contentable.uploads?.length;
  }

  get intervals() {
    const { reserve_min, reserve_max, step } = this.tile.contentable;

    const intervals: Interval[] = [];

    let reserveMin = reserve_min;
    let reserveMax = reserve_max;

    if (this.typeId == IntervalType.Hour) {
      reserveMin *= 60;
      reserveMax *= 60;
    }

    for (let value = reserveMin; value <= reserveMax; value += step) {
      const duration = moment.duration(value, "minutes");

      let name = "";

      if (duration.hours() > 0) {
        name += `${duration.hours()} ${this.$t('BOOKING.CAPACITY.HR')} `;
      }

      if (duration.minutes() > 0) {
        name += `${duration.minutes()} ${this.$t('BOOKING.CAPACITY.MIN')}`;
      }

      intervals.push({ name: name.trim(), value: value * 60 });
    }

    return intervals;
  }

  fetchAvailableSlots() {
    this.availableSlotsDialog = true;
  }

  fetchAvailableSlotsWithParams(params) {
    const formData = {
      id: this.tile.contentable.id,
      params: {
        ...this.formData,
        ...params,
      }
    };

    this.$store
      .dispatch("booking/fetchAvailableSlots", formData)
      .then(({ data }) => {
        this.availableSlots = data.data;
      })
      .catch(error => {
        this.formErrors = error.response.data.errors;
      });
  }

  showSlotDialog(slot: AvailableSlot) {
    this.selectedSlot = slot;
    this.slotDialog = true;
  }

  closeSlotDialog() {
    this.dialogErrors = null;
    this.selectedSlot = null;
    this.slotDialog = false;
  }

  closeAvailableSlotsDialog() {
    this.availableSlotsDialog = false;
    this.availableSlots = [];
  }

  storeReservation() {
    if (!this.selectedSlot) return;

    const formData = {
      person_id: this.userData.person.id,
      resource_id: this.tile.contentable.id,
      number_of_visitors: this.formData.number_of_visitors,
      date_from: this.formData.date_from,
      date_to: this.formData.date_to,
      time_from: moment(this.selectedSlot.start).format("HH:mm"),
      time_to: moment(this.selectedSlot.end).format("HH:mm")
    };

    this.$store
      .dispatch("booking/storeReservation", formData)
      .then(() => this.$router.push({ name: "booking.reservations" }))
      .catch(error => (this.dialogErrors = error.response.data.errors));
  }

  createReservation() {
    this.$router.push({
      name: "booking.reservations.create"
    });
  }

  onIntersect(entries: IntersectionObserverEntry[]) {
    this.intersecting = entries[0].isIntersecting;
  }

  hasRole(role: string): boolean {
    return this.userData.roles.some(r => r.slug == role);
  }

  scrollTo(id: string) {
    const el = document.getElementById(id);
    el?.scrollIntoView({ behavior: "smooth" });
  }

  back() {
    this.$router.back();
    this.scrollTo('card');
  }

}
